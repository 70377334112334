// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Ledges.io" width="100" />
         *
         */

        <svg width="145" height="32.45" viewBox="0 0 145 32.45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
            <path
                //This path is for the larger flame component
                d="M18.9,6.6L6.3,31.7c-3.5,0-6.3-2.8-6.3-6.3L12.6,0.3C16.1,0.3,18.9,3.1,18.9,6.6z"
                fill={theme.palette.primary.dark}
            />
            <path
                //This path is for the smaller flame component
                d="M18.9,31.7c-3.5,0-6.3-2.8-6.3-6.3l3.1-6.3c3.5,0,6.3,2.8,6.3,6.3L18.9,31.7z"
                fill={theme.palette.secondary.dark}
            />
            </g>
            <g>
            <path
                //This path is the "L" in Ledges.io
                d="M36.7,18.5h-9.7V5.1h2.8v10.8H37L36.7,18.5z"
                strokeWidth={0}
                stroke={theme.palette.grey[900]}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "e" in Ledges.io
                d="M47.4,13c0,1.3-0.9,1.5-1.9,1.5h-4.8c0.2,1.2,1.1,1.9,2.3,1.9c1.1,0,1.8-0.4,2.2-1l1.9,1c-1,1.7-2.3,2.3-4.3,2.3
		c-2.6,0-4.7-2.2-4.7-5.1c0-2.8,1.9-4.9,4.7-4.9C45.5,8.7,47.4,10.3,47.4,13z M40.8,12.7h4.3C45,11.6,44,11,43,11
		C42.1,11,41,11.6,40.8,12.7z"
                strokeWidth={0}
                stroke={theme.palette.grey[900]}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "d" in Ledges.io
                d="M49.5,13.5c0-3.5,2.1-4.8,4-4.8c1.3,0,2.1,0.5,2.3,0.8V4.7h2.6v12.2c0,0.4,0.1,0.9,0.3,1.4l-1.1,0.4
		c-0.5-0.2-0.9-0.7-1.1-1.1C56,18.3,55,18.7,54,18.7C51.5,18.7,49.5,16.6,49.5,13.5z M55.9,15.4v-3.2c-0.1-0.7-1.2-1.1-1.7-1.1
		C53,11,52,11.9,52,13.6c0,1.6,0.7,2.7,2.2,2.7C54.5,16.3,55.7,15.9,55.9,15.4z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "g" in Ledges.io
                d="M65.3,8.7c1.9,0,2.5,0.5,2.9,1.1c0.1-0.4,0.3-0.7,0.7-1.1l1.4,0.3c-0.2,0.6-0.3,1-0.3,1.5v6.8c0,3.2-1.8,4.7-4.6,4.7
		c-2.1,0-3.6-0.9-4.3-1.8l1.5-1.9c0.5,0.8,1.3,1.4,2.8,1.4c1.1,0,2.3-1,2.4-2.3c-0.3,0.6-1.5,1-2.6,1c-2.1,0-4.1-1.6-4.1-5
		C61.3,10.4,63,8.7,65.3,8.7z M67.6,15.4V12c-0.3-0.8-1.1-1-1.7-1c-1.2,0-2.2,1.1-2.2,2.4c0,1.9,1,2.8,2.2,2.8
		C66.4,16.3,67.4,15.9,67.6,15.4z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "e" in Ledges.io
                d="M82.3,13c0,1.3-0.9,1.5-1.9,1.5h-4.8c0.2,1.2,1.1,1.9,2.3,1.9c1.1,0,1.8-0.4,2.2-1l1.9,1c-1,1.7-2.3,2.3-4.3,2.3
		c-2.6,0-4.7-2.2-4.7-5.1c0-2.8,1.9-4.9,4.7-4.9C80.3,8.7,82.3,10.3,82.3,13z M75.7,12.7H80c-0.2-1.1-1.1-1.7-2.1-1.7
		C76.9,11,75.9,11.6,75.7,12.7z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "s" in Ledges.io
                d="M93.3,10.8l-2,1.4c-0.3-0.5-1.1-1.2-2.5-1.2c-0.7,0-1.4,0.1-1.4,0.7c0,0.4,0.7,0.6,1.5,0.7c2.8,0.6,4.5,0.9,4.5,3.2
		c0,2.5-2.4,3.1-4.4,3.1c-2.2,0-3.9-1-4.8-2.5l2.1-1.2c0.4,0.6,1.2,1.4,2.5,1.4c0.9,0,2.1,0,2.1-0.6c0-0.9-2.3-1-3.4-1.3
		C86.2,14,85,13.2,85,11.6c0-2,1.5-2.8,3.8-2.8C91.5,8.7,93.1,9.8,93.3,10.8z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "." in Ledges.io
                d="M98.3,17.3c0,0.7-0.5,1.2-1.3,1.2c-0.7,0-1.2-0.5-1.2-1.2c0-0.8,0.5-1.2,1.2-1.2C97.7,16.1,98.3,16.5,98.3,17.3z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "i" in Ledges.io
                d="M104.6,6.7c0,0.9-0.6,1.5-1.5,1.5c-0.8,0-1.5-0.6-1.5-1.5s0.6-1.5,1.5-1.5C104,5.2,104.6,5.8,104.6,6.7z M101.9,18.5V8.9
	        	h2.6v9.6H101.9z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "o" in Ledges.io
                d="M112.2,8.7c2.8,0,5.1,2.2,5.1,5c0,2.8-2.2,5-5.1,5c-2.9,0-5-2.2-5-5C107.2,10.9,109.3,8.7,112.2,8.7z M112.2,16.2
		        c1.4,0,2.6-1.2,2.6-2.6c0-1.3-1.2-2.5-2.6-2.5s-2.5,1.2-2.5,2.5C109.7,15.1,110.8,16.2,112.2,16.2z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            </g>
            <g>
            <path
                //This path is the "C" in Create understanding.
                d="M34.9,24.6L33.4,25c-0.3-1.2-1-1.8-2.4-1.8c-1.5,0-2.3,1.3-2.3,2.7c0,1.7,1,2.9,2.5,2.9c1.2,0,1.9-0.9,2.2-1.8l1.5,0.3
		c-0.4,1.7-1.7,2.9-3.7,2.9c-2.5,0-4-1.8-4-4.1c0-2.6,1.5-4.2,4.1-4.2C32.9,21.8,34.4,22.6,34.9,24.6z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "r" in Create understanding.
                d="M35.6,30.1v-5.6l0.8-0.1l0.3,1c0.3-0.5,0.9-1,1.8-1c0.5,0,0.9,0,1.2,0.3l-0.2,1.2c-0.4-0.2-0.7-0.3-1-0.3
		c-0.8,0-1.6,0.6-1.6,1.4v3.1H35.6z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "e" in Create understanding.
                d="M44.8,26.8c0,0.7-0.5,0.8-1,0.8h-3.2c0.1,0.8,0.8,1.4,1.6,1.4c0.7,0,1.2-0.3,1.4-0.7l1,0.5c-0.5,0.9-1.3,1.3-2.5,1.3
		c-1.5,0-2.8-1.3-2.8-3c0-1.7,1.1-2.9,2.8-2.9C43.7,24.3,44.8,25.3,44.8,26.8z M40.7,26.7h3c-0.1-0.8-0.7-1.3-1.5-1.3
		C41.4,25.4,40.8,25.9,40.7,26.7z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "a" in Create understanding.
                d="M48.9,26.4c0-0.7-0.5-1-1.2-1c-0.7,0-1.1,0.3-1.3,0.7l-0.8-0.8c0.2-0.4,1-1,2-1c1.8,0,2.5,0.7,2.5,2.6v2
		c0,0.3,0.3,0.6,0.7,0.6l-0.2,0.8c-0.7,0-1.1-0.3-1.3-0.6c-0.3,0.4-0.8,0.7-1.8,0.7c-1.6,0-2.3-0.7-2.3-1.8
		C45.2,26.3,48.2,26.8,48.9,26.4z M48.9,28.4v-1c-0.8,0.3-2.4,0-2.4,1.1c0,0.3,0.2,0.7,1.1,0.7C48.2,29.1,48.7,28.9,48.9,28.4z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "t" in Create understanding.
                d="M50.6,24.9l0.2-0.5h0.4v-1.1l1.4-1.1v2.2h2l-0.1,1.1h-1.9v2.2c0,0.9,0.5,1.4,0.9,1.4c0.5,0,0.7-0.1,0.9-0.4l0.7,1.1
		c-0.3,0.3-0.9,0.5-1.4,0.5c-1.5,0-2.5-0.8-2.5-2.6v-2.2L50.6,24.9z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "e" in Create understanding.
                d="M60.2,26.8c0,0.7-0.5,0.8-1,0.8H56c0.1,0.8,0.8,1.4,1.6,1.4c0.7,0,1.2-0.3,1.4-0.7l1,0.5c-0.5,0.9-1.3,1.3-2.5,1.3
		c-1.5,0-2.8-1.3-2.8-3c0-1.7,1.1-2.9,2.8-2.9C59,24.3,60.2,25.3,60.2,26.8z M56,26.7h3c-0.1-0.8-0.7-1.3-1.5-1.3
		C56.8,25.4,56.1,25.9,56,26.7z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "U" in Create understanding.
                d="M69.9,24.4V30l-0.5,0.1c-0.2-0.2-0.4-0.5-0.6-0.8c-0.4,0.5-1.1,0.8-2,0.8c-1.3,0-2.5-0.9-2.5-2.4v-3.5h1.3v3.2
		c0,0.8,0.6,1.4,1.4,1.4c0.6,0,1.6-0.5,1.6-1v-3.7H69.9z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "n" in Create understanding.
                d="M70.9,30.1v-5.6l0.5-0.1c0.2,0.2,0.4,0.5,0.6,0.8c0.4-0.5,1.1-0.8,2-0.8c1.3,0,2.6,0.9,2.6,2.4v3.4h-1.3v-3.2
		c0-0.8-0.7-1.4-1.5-1.4c-0.6,0-1.6,0.5-1.6,1v3.6H70.9z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                    fill={theme.palette.grey[900]}
                />
            <path
                //This path is the "d" in Create understanding.
                d="M77.2,27.1c0-2.1,1.3-2.9,2.5-2.9c0.7,0,1.1,0.2,1.5,0.5v-2.9h1.3v7.4c0,0.1,0.1,0.6,0.1,0.8l-0.5,0.2
		c-0.3-0.1-0.5-0.3-0.6-0.6c-0.5,0.4-0.9,0.6-1.6,0.6C78.4,30.2,77.2,28.9,77.2,27.1z M81.1,28.3v-2.1c-0.1-0.6-0.8-0.8-1.2-0.8
		c-0.8,0-1.5,0.6-1.5,1.8c0,1.1,0.5,1.8,1.5,1.8C80.2,29,81.1,28.7,81.1,28.3z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "e" in Create understanding.
                d="M88.5,26.8c0,0.7-0.5,0.8-1,0.8h-3.2c0.1,0.8,0.8,1.4,1.6,1.4c0.7,0,1.2-0.3,1.4-0.7l1,0.5c-0.5,0.9-1.3,1.3-2.5,1.3
		c-1.5,0-2.8-1.3-2.8-3c0-1.7,1.1-2.9,2.8-2.9C87.3,24.3,88.5,25.3,88.5,26.8z M84.3,26.7h3c-0.1-0.8-0.7-1.3-1.5-1.3
		C85.1,25.4,84.5,25.9,84.3,26.7z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "r" in Create understanding.
                d="M89.1,30.1v-5.6l0.8-0.1l0.3,1c0.3-0.5,0.9-1,1.8-1c0.5,0,0.9,0,1.2,0.3L93,25.9c-0.4-0.2-0.7-0.3-1-0.3
		c-0.8,0-1.6,0.6-1.6,1.4v3.1H89.1z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "s" in Create understanding.
                d="M98.3,25.4l-1,0.7c-0.2-0.3-0.6-0.7-1.5-0.7c-0.6,0-1.1,0.2-1.1,0.6s0.5,0.5,1.1,0.6c1.5,0.3,2.7,0.5,2.7,1.8
		c0,1.4-1.5,1.8-2.6,1.8c-1.3,0-2.3-0.5-2.8-1.3l1.1-0.6c0.2,0.4,0.7,0.8,1.6,0.8c0.6,0,1.4-0.2,1.4-0.6c0-0.3-0.4-0.5-0.8-0.6
		c-1.6-0.4-2.9-0.5-2.9-1.9c0-1.2,0.8-1.6,2.3-1.6C97.1,24.3,98,24.8,98.3,25.4z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "t" in Create understanding.
                d="M98.4,24.9l0.2-0.5H99v-1.1l1.4-1.1v2.2h2l-0.1,1.1h-1.9v2.2c0,0.9,0.5,1.4,0.9,1.4c0.5,0,0.7-0.1,0.9-0.4l0.7,1.1
		c-0.3,0.3-0.9,0.5-1.4,0.5c-1.5,0-2.5-0.8-2.5-2.6v-2.2L98.4,24.9z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "a" in Create understanding.
                d="M106.4,26.4c0-0.7-0.5-1-1.2-1c-0.7,0-1.1,0.3-1.3,0.7l-0.8-0.8c0.2-0.4,1-1,2-1c1.8,0,2.5,0.7,2.5,2.6v2
		c0,0.3,0.3,0.6,0.7,0.6l-0.2,0.8c-0.7,0-1.1-0.3-1.3-0.6c-0.3,0.4-0.8,0.7-1.8,0.7c-1.6,0-2.3-0.7-2.3-1.8
		C102.7,26.3,105.7,26.8,106.4,26.4z M106.4,28.4v-1c-0.8,0.3-2.4,0-2.4,1.1c0,0.3,0.2,0.7,1.1,0.7
		C105.7,29.1,106.2,28.9,106.4,28.4z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "n" in Create understanding.
                d="M108.7,30.1v-5.6l0.5-0.1c0.2,0.2,0.4,0.5,0.6,0.8c0.4-0.5,1.1-0.8,2-0.8c1.3,0,2.6,0.9,2.6,2.4v3.4h-1.3v-3.2
		c0-0.8-0.7-1.4-1.5-1.4c-0.6,0-1.6,0.5-1.6,1v3.6H108.7z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "d" in Create understanding.
                d="M114.9,27.1c0-2.1,1.3-2.9,2.5-2.9c0.7,0,1.1,0.2,1.5,0.5v-2.9h1.3v7.4c0,0.1,0.1,0.6,0.1,0.8l-0.5,0.2
		c-0.3-0.1-0.5-0.3-0.6-0.6c-0.5,0.4-0.9,0.6-1.6,0.6C116.1,30.2,114.9,28.9,114.9,27.1z M118.9,28.3v-2.1c-0.1-0.6-0.8-0.8-1.2-0.8
		c-0.8,0-1.5,0.6-1.5,1.8c0,1.1,0.5,1.8,1.5,1.8C117.9,29,118.8,28.7,118.9,28.3z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "i" in Create understanding.
                d="M122.7,23c0,0.4-0.3,0.8-0.8,0.8c-0.5,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8C122.3,22.2,122.7,22.6,122.7,23z
		 M121.2,30.1v-5.7h1.3v5.7H121.2z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "n" in Create understanding.
                d="M123.6,30.1v-5.6l0.5-0.1c0.2,0.2,0.4,0.5,0.6,0.8c0.4-0.5,1.1-0.8,2-0.8c1.3,0,2.6,0.9,2.6,2.4v3.4h-1.3v-3.2
		c0-0.8-0.7-1.4-1.5-1.4c-0.6,0-1.6,0.5-1.6,1v3.6H123.6z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "g" in Create understanding.
                d="M132.2,24.3c0.9,0,1.4,0.3,1.8,0.6c0.1-0.2,0.2-0.4,0.4-0.6l0.6,0.2c-0.1,0.3-0.1,0.5-0.1,0.8v4.2c0,1.8-1.1,2.8-2.8,2.8
		c-1.3,0-2-0.5-2.4-1l0.8-0.9c0.3,0.4,0.7,0.7,1.6,0.7c0.9,0,1.7-0.6,1.7-1.7c-0.3,0.5-0.9,0.7-1.6,0.7c-1.3,0-2.4-1-2.4-3
		C129.8,25.3,130.9,24.3,132.2,24.3z M133.7,28.3v-2.1c-0.2-0.6-0.8-0.7-1.2-0.7c-0.8,0-1.5,0.7-1.5,1.6c0,1.3,0.6,1.9,1.6,1.9
		C132.9,29,133.6,28.7,133.7,28.3z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
            />
            <path
                //This path is the "." in Create understanding.
                d="M137.6,29.4c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.5,0.3-0.7,0.7-0.7C137.3,28.6,137.6,28.9,137.6,29.4z"
                strokeWidth={0}
                stroke={theme.palette.primary.main}
                fill={theme.palette.grey[900]}
                />
                </g>
        </svg>
    );
};

export default Logo;
