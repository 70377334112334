// assets
import { IconFriends, IconMailbox, IconShieldLock } from '@tabler/icons';

// constant
const icons = { IconFriends, IconMailbox, IconShieldLock };

// ==============================|| DESKTOP MENU ITEMS ||============================== //

const tabletop = {
    id: 'tabletop',
    type: 'group',
    children: [
        {
            id: 'community',
            title: 'Community',
            type: 'collapse',
            icon: icons.IconFriends,

            children: [
                {
                    id: 'socials',
                    title: 'Socials',
                    type: 'item',
                    url: '/community/socials',
                },
                {
                    id: 'blog',
                    title: 'Blog',
                    type: 'item',
                    url: '/community/blog',
                }
            ]
        },
        {
            id: 'inbox',
            title: 'Inbox',
            type: 'item',
            url: '/inbox',
            icon: icons.IconMailbox,
            breadcrumbs: false
        },
        {
            id: 'wallet',
            title: 'Wallet',
            type: 'item',
            url: '/wallet',
            icon: icons.IconShieldLock,
            breadcrumbs: false
        },
    ]
};

export default tabletop;