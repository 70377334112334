// assets
import { IconDashboard, IconBulb, IconBuildingSkyscraper, IconDatabaseImport, IconApps, IconReportAnalytics, IconFriends, IconMailbox, IconShieldLock } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconBulb, IconBuildingSkyscraper, IconDatabaseImport, IconApps, IconReportAnalytics, IconFriends, IconMailbox, IconShieldLock };

// ==============================|| DESKTOP MENU ITEMS ||============================== //

const desktop = {
    id: 'desktop',
    title: "Desktop",
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'learn',
            title: 'Learn',
            type: 'item',
            url: '/learn',
            icon: icons.IconBulb,
            breadcrumbs: false
        },
        {
            id: 'earn',
            title: 'Earn',
            type: 'item',
            url: '/earn',
            icon: icons.IconBuildingSkyscraper,
            breadcrumbs: false
        },
        {
            id: 'portfolio',
            title: 'Portfolio',
            type: 'item',
            url: '/portfolio',
            icon: icons.IconApps,
            breadcrumbs: false
        },
        {
            id: 'analytics',
            title: 'Analytics',
            type: 'item',
            url: '/analytics',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false
        },
        {
            id: 'archives',
            title: 'Archives',
            type: 'item',
            url: '/archives',
            icon: icons.IconDatabaseImport,
            breadcrumbs: false
        },
    ]
};

export default desktop;
