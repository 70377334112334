import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
// desktop routing
// import universal views
const DashboardDefault = Loadable(lazy(() => import('views/widgetCategoryNav/universal/dashboard')));
const ArchivesTempPage = Loadable(lazy(() => import('views/widgetCategoryNav/universal/archives')));
const AnalyticsTempPage = Loadable(lazy(() => import('views/widgetCategoryNav/universal/analytics')));
//import community views
const CommunityTempPage = Loadable(lazy(() => import('views/widgetCategoryNav/universal/community/home.js')));
const BlogPage = Loadable(lazy(() => import('views/widgetCategoryNav/universal/community/blog/index.js')));
const SocialsPage = Loadable(lazy(() => import('views/widgetCategoryNav/universal/community/socials.js')));

const InboxTempPage = Loadable(lazy(() => import('views/widgetCategoryNav/universal/inbox')));
const WalletTempPage = Loadable(lazy(() => import('views/widgetCategoryNav/universal/wallet')));
// import student spec views
const LearnTempPage = Loadable(lazy(() => import('views/widgetCategoryNav/studentSpec/learn')));
const EarnTempPage = Loadable(lazy(() => import('views/widgetCategoryNav/studentSpec/earn')));
const PortfolioTempPage = Loadable(lazy(() => import('views/widgetCategoryNav/studentSpec/portfolio')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const AboutMarusPage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/learn',
            element: <LearnTempPage />
        },
        {
            path: '/earn',
            element: <EarnTempPage />
        },
        {
            path: '/archives',
            element: <ArchivesTempPage />
        },
        {
            path: '/portfolio',
            element: <PortfolioTempPage />
        },
        {
            path: '/analytics',
            element: <AnalyticsTempPage />
        },
        {
            path: '/inbox',
            element: <InboxTempPage />
        },
        // community routes
        {
            path: '/community',
            element: <CommunityTempPage />
        },
        {
            path: '/community/socials',
            element: <SocialsPage />
        },
        {
            path: '/community/blog',
            element: <BlogPage />
        },
        {
            path: '/wallet',
            element: <WalletTempPage />
        },

        // other routes
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/socials/finance-linkedin',
            element: <UtilsTablerIcons />
        },
        {
            path: '/socials/finance-facebook',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/sample-page',
            element: <AboutMarusPage />
        }
    ]
};

export default MainRoutes;
