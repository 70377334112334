import desktop from './desktop';
import tabletop from './tabletop';

import pages from './pages';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [desktop, , tabletop, pages, utilities, other]
};

export default menuItems;
